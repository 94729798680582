import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';


const b3_jobsDisplay = {
	rootElement: 'root',
	uri: 'https://shik-engine.bytt.app',
	mediaUri: 'https://shik-media.bytt.app',
	limit: 10,
	showMoreButton: true,
	showLogo: false,
	accountId: false,
	order: false
}
const options = window?.b313?.jobsDisplay ? { ...b3_jobsDisplay, ...window?.b313?.jobsDisplay } : b3_jobsDisplay;
const root = ReactDOM.createRoot(document.getElementById(options.rootElement));
root.render(
  <React.StrictMode>
    <App options={options} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
